import Counter from 'components/UiNew/Counter';
import Text from 'components/UiNew/typography/text';

const CounterFilter = ({ label, value, setValue }) => {
  return (
    <div className="flex items-center justify-between px-3 py-2">
      <Text className="block !text-sm font-bold capitalize text-gray-dark lg:!text-base">
        {label}
      </Text>
      <Counter
        count={value}
        countBy={1}
        onCount={(val) => setValue(val)}
        buttonClassName="rounded-md !px-1 w-[30px]"
      />
    </div>
  );
};

export default CounterFilter;
