import React, { Fragment, useEffect, useState } from 'react';
import './PaymentResponse.css';
import { formatCurrencyAmount } from 'containers/Confirmation/utils';
import { Button, Divider, Tag, notification } from 'antd';
import { FaArrowRight } from 'react-icons/fa';
import Heading from 'components/UI/Heading/Heading';
import useWindowSize from 'library/hooks/useWindowSize';
import moment from 'moment';
import PaymentService from 'service/PaymentService';
import { useLocation, useNavigate } from 'react-router-dom';

const PaymentResponse = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const confirmationHandler = () => {
    navigate('/');
  };

  const [bookingResponse, setBookingResponse] = useState();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const merchantTransaction = query.get('merchantTransactionId');
    const bookingId = query.get('bookingId');
    const data = {
      bookingId: bookingId,
      merchantTransactionId: merchantTransaction,
    };
    // console.log('merchantTransaction', merchantTransaction);
    PaymentService.makeBooking(data)
      .then((res) => {
        console.log('RESULT: ', res.result);
        setBookingResponse(res?.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log('error: ', err);
        setLoading(false);
        notification.error({
          message: 'Oops! Something went wrong.',
          description: 'Some error occured. Please try again later.',
          placement: 'bottomRight',
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const PriceBreakdown = bookingResponse?.priceBreakdown;
  // const bookingPrice =
  //   PriceBreakdown?.baseAmount +
  //   PriceBreakdown?.taxAmount +
  //   PriceBreakdown?.serviceFeeAmount +
  //   PriceBreakdown?.additionalChargesAmount -
  //   PriceBreakdown?.discountAmount;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      {loading && (
        <img
          src="/images/spinner-loading.gif"
          alt="loader"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '60px',
            width: '15%',
          }}
        />
      )}

      {!loading && (
        <div className="container">
          <div className="content">
            {width >= 750 && (
              <Heading
                as="h1"
                textAlign={width > 500 ? 'left' : 'center'}
                content={
                  bookingResponse?.paymentDetails?.paymentStatus === 'COMPLETED'
                    ? 'Booking Confirmation'
                    : 'Booking Unsuccessful'
                }
                style={{
                  marginBottom: width > 500 ? '40px' : '20px',
                }}
              />
            )}
            <img
              src={
                bookingResponse?.paymentDetails?.paymentStatus === 'COMPLETED'
                  ? '/images/congrats.png'
                  : '/images/failPayment.png'
              }
              alt="congrats"
              width={100}
              height={100}
              style={{ marginBottom: '20px' }}
            />

            <Tag
              color={
                bookingResponse?.paymentDetails?.paymentStatus === 'COMPLETED'
                  ? 'cyan'
                  : 'red'
              }
              style={{
                padding: '4px 6px',
                fontSize: '14px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '20px',
              }}
            >
              <img
                src={
                  bookingResponse?.paymentDetails?.paymentStatus === 'COMPLETED'
                    ? '/images/success.png'
                    : '/images/cross.png'
                }
                style={{
                  marginRight: '10px',
                  marginLeft: '4px',
                  height: '25px',
                  width: '25px',
                }}
                alt="Success Icon"
              />
              {bookingResponse &&
              bookingResponse?.paymentDetails?.paymentStatus === 'COMPLETED' ? (
                <span style={{ whiteSpace: 'normal', width: '500px' }}>
                  YOHO !! Your Reservation is Confirmed . A confirmation email
                  with further details has been sent to your email address.
                </span>
              ) : (
                <span style={{ whiteSpace: 'normal', width: '500px' }}>
                  We regret to inform you that your booking could not be
                  completed due to a payment issue. Please verify your payment
                  details and try again.
                </span>
              )}
            </Tag>

            <div className="trip-details">
              <div className="trip-item">
                <span className="label">Check In: </span>
                <span className="value">
                  {moment(bookingResponse?.bookingDetails?.checkIn).format(
                    'DD-MMM-YYYY',
                  )}
                </span>
                {/* <a href="#edit" className="edit-link">
              Edit
            </a> */}
              </div>
              <div className="trip-item">
                <span className="label">Check Out: </span>
                <span className="value">
                  {moment(bookingResponse?.bookingDetails?.checkOut).format(
                    'DD-MMM-YYYY',
                  )}
                </span>
              </div>
              <div className="trip-item">
                <span className="label">Guests: </span>
                <span className="value">
                  {bookingResponse?.bookingDetails?.totalGuests}
                </span>
              </div>

              <div className="trip-item">
                <span className="label">Name : </span>
                <span className="value">
                  {bookingResponse?.bookingDetails?.leadGuestDetails?.guestName}
                </span>
              </div>

              <Divider style={{ margin: '30px 0px' }} />

              <Button
                type="primary"
                className="pay-btn"
                onClick={confirmationHandler}
                loading={loading}
              >
                Go to HomePage
                <FaArrowRight />
              </Button>
            </div>
          </div>
          <div className="sidebar">
            <div className="sticky-card">
              <img
                src={bookingResponse?.propertyDetails?.thumbnailUrl}
                alt="Wooded Luxury Attic Suite"
                className="card-image"
              />
              <div className="card-details">
                <h3> {bookingResponse?.propertyDetails?.title}</h3>
              </div>
              <div className="pricing">
                <div className="price-item">
                  <span>Booking Price:</span>
                  <span>
                    {formatCurrencyAmount.format(
                      bookingResponse?.paymentDetails?.amount,
                    )}
                  </span>
                </div>

                {/* <div className="price-item">
                  <span>Reservation Code:</span>
                  <span>SBS12768OOPS</span>
                </div> */}
                <div className="price-item">
                  <span>Transaction ID:</span>
                  <span>{bookingResponse?.paymentDetails?.transactionId}</span>
                </div>
                <div className="total">
                  <span>STATUS</span>
                  <span>{bookingResponse?.paymentDetails?.paymentStatus}</span>
                </div>
              </div>
            </div>
          </div>
          {width < 750 && (
            <Heading
              as="h2"
              textAlign={width > 500 ? 'left' : 'center'}
              content={'Confirm and pay'}
              style={{
                marginBottom: width > 500 ? '20px' : '0px',
              }}
            />
          )}
        </div>
      )}
    </Fragment>
  );
};

export default PaymentResponse;
