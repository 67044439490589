export const transformPropertyDataFromServer = (
  serverData,
  propertyTypeListMap,
  propertyOfferingsMap,
  cityMap,
  typeMap,
) => {
  const {
    amenities,
    bnbUrl,
    description,
    details,
    location,
    onboardingStage,
    propertyId,
    propertyOffering,
    propertyTypeId,
    title,
  } = serverData;

  const offerings = {};
  const offeringsMap = {};
  details.forEach((detail) => {
    offerings[detail.propertyDetailsId] = detail.detailsValue;
    offeringsMap[detail.propertyDetailsId] = { ...detail };
  });

  let subType = null;
  if (propertyOffering.description) {
    const subTypeId = typeMap.filter(
      (item) => item.name === propertyOffering.description,
    );

    subType = {
      id: subTypeId[0]?.id,
      name: subTypeId[0]?.name,
    };
  }

  const dataToSet = {
    propertyId,
    detail: {
      title,
      description,
      bnbUrl,
    },
    offeringsMap: offeringsMap,
    offerings: { ...offerings },
    location: {
      ...location,
      country: 'India (IN)',
      district: location.locality,
      coordinates: { lat: location.latitude, lng: location.longitude },
      zoneId: 1,
      city: location?.cityId?.toString() || '',
      state: '1',
    },
    amenitiesObject: [...amenities],
    amenities: amenities.map((amenityItem) => amenityItem.name),
    onboardingStage,
    type: { id: propertyOffering.id, name: propertyOffering.name },
    subType: { ...subType }, //TODO
    place: {
      id: propertyTypeId,
      name: propertyTypeListMap[propertyTypeId].name,
    },
  };

  return dataToSet;
};

export const transformPropertyPricing = (serverData) => {
  const { propertyPrices, discounts, additionalCharges, defaultPrice, pricingRangeMetadata, propertyCalendarPrices, maxGuestAllowed } =
    serverData;

  const pricingDetails = {
    weekday: {
      priceId: !propertyCalendarPrices ? propertyPrices[0].priceId : 0,
      pricePerNight: !propertyCalendarPrices ? propertyPrices[0].pricePerNight : 0,
      numberOfGuests: !propertyCalendarPrices ? propertyPrices[0].maxGuestAllowed: 0,
    },
    weekend: {
      priceId: !propertyCalendarPrices ? propertyPrices[1].priceId : 0,
      pricePerNight: !propertyCalendarPrices ?  propertyPrices[1].pricePerNight: 0,
      numberOfGuests: !propertyCalendarPrices ? propertyPrices[1].maxGuestAllowed: 0,
    },
    maxGuestAllowed: {
      numberOfGuests: maxGuestAllowed,
    },
    propertyCalendarPrices: propertyCalendarPrices ? propertyCalendarPrices.reduce((acc, item) => {
      acc[item.priceDate] = item.pricePerNight;
      return acc;
    }, {}) : null,
    additionalCharges: additionalCharges.map((additionalCharge) => {
      return {
        ...additionalCharge,
        amount: additionalCharge.pricePerNight,
        paymentType: additionalCharge.chargeType,
      };
    }),
    friday: {
      priceId: !propertyCalendarPrices ? propertyPrices[2].priceId : 0,
      isSelected:
      !propertyCalendarPrices && (propertyPrices[0].pricePerNight === propertyPrices[2].pricePerNight)
          ? 'weekday'
          : 'weekend',
    },

    discounts: {
      lessThan24Hours: discounts.length > 0 ? discounts[0].discountPercentage : 0,
      between24HoursTo72Hours: discounts.length > 0 ? discounts[1].discountPercentage : 0,
      equalToOrMoreThan72Hours: discounts.length > 0 ? discounts[2].discountPercentage: 0,
      equalToOrMoreThan7Days: discounts.length > 0 ? discounts[3].discountPercentage: 0,
    },
    discountStore: discounts.length > 0 ? {
      lessThan24Hours: discounts[0],
      between24HoursTo72Hours: discounts[1],
      equalToOrMoreThan72Hours: discounts[2],
      equalToOrMoreThan7Days: discounts[3],
    } : null,
    basePrice: pricingRangeMetadata ? JSON.parse(pricingRangeMetadata) : {},
    defaultPrice: defaultPrice,
    //  cleaningFees: {
    //    lessThan3NightsBooking: {
    //      paymentType: 'one_time',
    //      amount: 0,
    //    },
    //    moreThan3NightsBooking: {
    //      paymentType: 'one_time',
    //      amount: 0,
    //    },
  };

  return pricingDetails;
};

export function convertDateFormat(dateString) {
  console.log(dateString);
  if (dateString) {
    const [month, day, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  } else return null;
}

export function convertArrayFormat(data) {
  if (data) {
    const updatedData = data.split(',').map(Number);
    return updatedData;
  } else return null;
}

export const formatCurrencyAmount = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 2,
});

export const PRICE_BREAKDOWN_KEYS = {
  basic: 'Base Price',
  'Price Per Additional Guest': 'Price for Additional Guest',
  'Cleaning fee': 'Cleaning Fee',
  serviceFee: 'Service Fee',
  tax: 'GST',
  discount: 'Discount',
};

export function dateDifference(date1, date2) {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  const diffTime = Math.abs(d2 - d1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}
