import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  acceptBidResponse: {},
  place: {},
  type: null,
  subType: null,
  discountStore: null,
  location: {
    country: 'India-IN',
    addressLine1: '',
    addressLine2: '',
    pincode: '',
    landmark: '',
    district: '',
    city: '',
    cityObject: {},
    state: '',
    coordinates: {
      lat: '',
      lng: '',
    },
  },
  images: {
    imageUrlList: {},
    thumbnail: {},
  },
  offerings: null,
  offeringsMap: {},
  detail: {
    title: '',
    description: '',
    bnbUrl: '',
  },
  amenities: [],
  amenitiesObject: [],
  propertyPhotos: [],
  storedImages: {},
  thumbnailUrl: null,
  pricingDetails: {
    defaultPrice: null,
    maxGuestAllowed: {
      numberOfGuests: 0,
    },
    propertyCalendarPrices: null,
    weekday: {
      pricePerNight: 0,
      numberOfGuests: 0,
    },
    weekend: {
      pricePerNight: 0,
      numberOfGuests: 0,
    },
    additionalCharges: [{}, {}, {}],
    friday: {
      isSelected: '',
    },
    cleaningFees: {
      lessThan3NightsBooking: {
        paymentType: 'one_time',
        amount: 0,
      },
      moreThan3NightsBooking: {
        paymentType: 'one_time',
        amount: 0,
      },
    },
    discounts: {
      lessThan24Hours: 0,
      between24HoursTo72Hours: 0,
      equalToOrMoreThan72Hours: 0,
      equalToOrMoreThan7Days: 0,
    },
    basePrice: {
      lessThan24Hours: {
        start: 70,
        end: 100,
      },
      between24HoursTo72Hours: {
        start: 70,
        end: 100,
      },
      equalToOrMoreThan72Hours: {
        start: 70,
        end: 100,
      },
      equalToOrMoreThan7Days: {
        start: 70,
        end: 100,
      },
    },
  },
  additionalChargesMap: {},
  bookingSettings: {
    checkInTime: '',
    checkOutTime: '',
    bookingApproval: '',
    maxDayBooking: '',
    minDayBooking: '',
    cancellationPolicy: {
      Upto4H: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      Upto12H: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      Upto24H: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      Upto72H: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      Upto7D: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
      MoreThan7D: {
        fullRefund: false,
        noRefund: false,
        partialRefund: 0,
      },
    },
    restrictions: [],
  },
  propertyId: 0,
  onboardingStage: null,
  priceId: '',
  discountId: '',
};
export const placeSlice = createSlice({
  name: 'placeDetails',
  initialState,
  reducers: {
    addPlace: (state, action) => {
      state.place = action.payload;
    },

    addType: (state, action) => {
      state.type = action.payload;
    },

    addSubType: (state, action) => {
      state.subType = action.payload;
    },

    addLocation: (state, action) => {
      state.location = action.payload;
    },
    addOfferings: (state, action) => {
      state.offerings = action.payload;
    },

    addOfferingsMap: (state, action) => {
      state.offeringsMap = action.payload;
    },

    addDetails: (state, action) => {
      state.detail = action.payload;
    },

    addAmenities: (state, action) => {
      state.amenities = action.payload;
    },

    addAmenitiesObject: (state, action) => {
      state.amenitiesObject = action.payload;
    },

    addPricingDetails: (state, action) => {
      state.pricingDetails = action.payload;
    },
    addBookingSettings: (state, action) => {
      state.bookingSettings = action.payload;
    },
    setPropertyId: (state, action) => {
      state.propertyId = action.payload;
    },
    setAdditionalChargesMap: (state, action) => {
      state.additionalChargesMap = action.payload;
    },
    addStoredImages: (state, action) => {
      state.storedImages = action.payload;
    },
    overwritePropertyDetails: (state, action) => {
      state.detail = action.payload.detail;
      state.propertyId = action.payload.propertyId;
      state.amenities = action.payload.amenities;
      state.subType = action.payload.subType;
      state.type = action.payload.type;
      state.place = action.payload.place;
      state.location = action.payload.location;
      state.offerings = action.payload?.offerings;
    },
    overwritePricing: (state, action) => {
      state.pricingDetails.additionalCharges = action.payload.additionalCharges;
      state.pricingDetails.discounts = action.payload.discounts;
      state.pricingDetails.weekday = action.payload.weekday;
      state.pricingDetails.weekend = action.payload.weekend;
      state.pricingDetails.propertyCalendarPrices = action.payload.propertyCalendarPrices;
      state.pricingDetails.defaultPrice = action.payload?.defaultPrice;
      state.pricingDetails.maxGuestAllowed = action.payload.maxGuestAllowed;
      state.discountStore = action.payload.discountStore;
    },
    addThumbnailUrl: (state, action) => {
      let index = state.storedImages.length;
      console.log(action.payload);
      state.storedImages = [...state.storedImages, {other: [action.payload]}];
      state.thumbnailUrl = index;
    },
    updateOnboardingStage: (state, action) => {
      state.onboardingStage = action.payload;
    },
    updatePriceId: (state, action) => {
      state.priceId = action.payload;
      
    },
    updateDiscountId: (state, action) => {
      state.discountId = action.payload;
    },
    setImages: (state, action) => {
        state.images.imageUrlList = action.payload.imageUrlList;
        state.images.thumbnail = action.payload.thumbnail;
    },
    addImage: (state, action) => {
      console.log(action);
      state.images.imageUrlList= {
          ...state.images.imageUrlList,
          [action.payload.category]: state.images.imageUrlList[action.payload.category] ? [
              ...state.images.imageUrlList[action.payload.category],
              action.payload.imageUrl,
          ] : [action.payload.imageUrl],
      };
      console.log(state.images.imageUrlList);
    },
    deleteImage: (state, action) => {
      state.images.imageUrlList = {
          ...state.images.imageUrlList,
          [action.payload.category]: state.images.imageUrlList[action.payload.category].filter(
              (url) => url !== action.payload.url
          ),
      };
    },
    setThumbnail: (state, action) => {
      console.log(action.payload);
      state.images.thumbnail = "https://bidyourstay.s3.ap-south-1.amazonaws.com/167/images/e0bb5fec-5c88-4224-9917-cc017800f11b_1719253948990.png";
    },
    setAcceptBidResponse: (state, action) => {
      state.acceptBidResponse = action.payload;
    },
    updateStore: (state, action) => {
      return initialState;
    },
  },
});

export const {
  addPlace,
  addType,
  addSubType,
  addLocation,
  addOfferings,
  addDetails,
  addAmenities,
  addAmenitiesObject,
  addPricingDetails,
  addBookingSettings,
  setPropertyId,
  addOfferingsMap,
  setAdditionalChargesMap,
  addStoredImages,
  overwritePropertyDetails,
  overwritePricing,
  addThumbnailUrl,
  updateOnboardingStage,
  updateStore,
  setImages,
  addImage,
  deleteImage,
  setThumbnail,
  setAcceptBidResponse
} = placeSlice.actions;

export default placeSlice.reducer;
