import React, { useEffect, useState, useRef } from 'react';
import styles from './Navbar.module.css';
import Logo from '../Logo/Logo';
import { LOGIN_PAGE } from 'settings/constant';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [userName, setUserName] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const profileRef = useRef(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  useEffect(() => {
    const hostDetails = localStorage.getItem('userDetails');
    if (hostDetails) {
      const hostDetailsJson = JSON.parse(hostDetails);
      setLoggedIn(true);
      let user = hostDetailsJson.firstName;
      setUserName(user);
    }
  }, []);

  const goToMyBids = () => {
    setShowMenu(false);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {
      window.open(`/host/my-bids`, '_blank');
    }
  };

  const logOut = (e) => {
    e.stopPropagation();
    e.preventDefault();
    localStorage.clear();
    navigate(LOGIN_PAGE);
  };

  const menuItems = [
    {
      label: (
        <button
          type="primary"
          onClick={goToMyBids}
          style={{ color: 'gray', background: 'transparent', border: 'none' }}
        >
          Recieved Bids
        </button>
      ),
      key: 'recieved_bids',
    },
    {
      label: (
        <button
          type="primary"
          onClick={() => navigate('/host/myBookings')}
          style={{ color: 'gray', background: 'transparent', border: 'none' }}
        >
          Bookings
        </button>
      ),
      key: 'recieved_bids',
    },
    {
      label: (
        <button
          type="primary"
          style={{ color: 'gray', background: 'transparent', border: 'none' }}
          onClick={logOut}
        >
          Log Out
        </button>
      ),
      key: 'log_out',
    },
  ];

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      profileRef.current &&
      !profileRef.current.contains(event.target)
    ) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.navbar}>
      <div style={{ margin: '18px' }}>
        <Logo
          withLink
          linkTo="/"
          src="/images/bys_final_logo.png"
          title="Bid your Stay"
        />
      </div>

      {loggedIn && (
        <div className={styles.profileMenuContainer}>
          <div
            ref={profileRef}
            className={styles.profileContainer}
            onClick={handleToggle}
          >
          <img 
          style={{ borderRadius: 50, marginBottom: 10 }}
          src="/images/user_icon_2.png" alt="user" width='35px' />
            <h2 className={styles.usergreeting}>{userName}</h2>
          </div>
          {showMenu && (
            <div ref={menuRef} className={styles.menu}>
              {menuItems.map((item) => (
                <div key={item.key} className={styles.menuItem}>
                  {item.label}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
