import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import LogoArea from './Logo.style';

const Logo = ({ className, withLink, linkTo, src }) => {
  return (
    <LogoArea className={className}>
      {withLink ? (
        <NavLink to={linkTo}>
          {src ? (
            <img
              style={{ width: 125, opacity: 1 }}
              src={src}
              alt="Profile Icon."
            />
          ) : (
            <img
              style={{ width: 125, marginTop: 10, opacity: 1 }}
              src="/images/bys_final_logo.png"
              alt="BidYourStay Logo."
            />
          )}
        </NavLink>
      ) : (
        <Fragment>
          {src ? (
            <img
              style={{ opacity: 1 }}
              width="125"
              src={src}
              alt="Profile Icon."
            />
          ) : (
            <img
              style={{ width: 125, marginTop: 10, opacity: 1 }}
              width="125"
              src="/images/bys_final_logo.png"
              alt="BidYourStay Logo."
            />
          )}
        </Fragment>
      )}
    </LogoArea>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  withLink: PropTypes.bool,
  src: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.string,
};

export default Logo;
