import { CardsContainer } from 'components/common/BiddingCard/BiddingCard.style';
import React, { Fragment, useEffect, useState } from 'react';
import PaymentService from 'service/PaymentService';
import BookingCard from './BookingCard';

const Bookings = ({ userType }) => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (userType === 'HOST') {
      PaymentService.getBookingsForHost()
        .then((res) => {
          setBookings(res.result);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    } else {
      PaymentService.getBookingsByUser()
        .then((res) => {
          setBookings(res.result);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }
  }, [userType]);

  return (
    <>
      {loading && (
        <img
          src="/images/spinner-loading.gif"
          alt="loader"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '60px',
            width: '15%',
          }}
        />
      )}
      {!loading && (
        <CardsContainer>
          {bookings && !loading && bookings.length > 0 ? (
            bookings.map((card, index) => (
              <BookingCard key={index} bookings={card}></BookingCard>
            ))
          ) : (
            <Fragment>
              {!loading && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                  }}
                >
                  <img
                    style={{
                      width: '200px',
                      height: '200px',
                      marginBottom: '30px',
                    }}
                    src="/images/sad-dog.png"
                    alt="Logo"
                  />
                  <h1
                    style={{
                      fontSize: '2rem',
                      color: '#333',
                      fontWeight: '600',
                    }}
                  >
                    Oops! No Bookings Yet .
                  </h1>
                  <h2>
                    It looks like you haven't booked any property yet.Please
                    explore our listings and make a reservation to get started.
                  </h2>
                </div>
              )}
            </Fragment>
          )}
        </CardsContainer>
      )}
    </>
  );
};

export default Bookings;
