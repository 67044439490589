import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'themes/global.style';
import AuthProvider from 'context/AuthProvider';
import AppRoutes from './router';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import './index.css';
import FireAuth from 'context/FireAuth';
import { store } from './store/store';
import { Provider } from 'react-redux';
// import { Button, defaultTheme } from '@adobe/react-spectrum';
const App = () => (
  <ThemeProvider theme={theme}>
    <ConfigProvider theme={{ hashed: false }}>
      <React.Fragment>
        <Provider store={store}>
          <GlobalStyles />
          <BrowserRouter>
            <FireAuth>
              <AuthProvider>
                <AppRoutes />
              </AuthProvider>
            </FireAuth>
          </BrowserRouter>
        </Provider>
      </React.Fragment>
    </ConfigProvider>
  </ThemeProvider>
);
const root = createRoot(document.getElementById('root'));
root.render(<App />);
