import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${themeGet('border.3', '#E6E6E6')};
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin: 10px;
  width: 100%; /* Full width by default */
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow:
      rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%; /* Full width for small screens */
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%; /* One card per row on medium screens */
  }

  @media (min-width: 1024px) {
    width: calc(50% - 20px); /* Two cards per row on large screens */
  }

  .card-image {
    width: 100%;
    height: auto;

    @media (min-width: 767px) {
      width: 40%;
    }

    img {
      width: 100%;
      height: 100%;
      padding: 14px;
      border-right: 1px solid ${themeGet('border.3', '#E6E6E6')};
      object-fit: cover;

      @media (max-width: 767px) {
        padding-bottom: 0;
      }
    }
  }

  .card-content {
    padding: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      cursor: pointer;

      .card-title {
        font-size: 18px;
        font-weight: 700;
        color: ${themeGet('text.0', '#2C2C2C')};

        &:hover {
          color: ${themeGet('primary.0', '#155a93')};
          text-decoration: underline;
        }
      }
    }

    .expired {
      text-align: center;
      padding: 5px 0px;
      font-size: 14px;
      font-weight: 300;
    }

    .card-details {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      gap: 10px;
      padding-top: 10px;

      .title-container {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
          flex-direction: column-reverse;
        }

        .counter-container {
          display: flex;
          align-items: center;
          // justify-content: center;
          gap: 0.5em;

          @media (max-width: 767px) {
            margin-bottom: 20px;
          }
        }

        .counter {
          font-size: 15px;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 0;

          @media (max-width: 767px) {
            flex-direction: row;
            gap: 10px;
          }
        }
      }

      .margin-bottom-0 {
        margin-bottom: 0;
      }
      .price-label {
        font-size: 14px;
        color: #726868;
        margin-bottom: 5px;
      }
      .price-details {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .price-container {
        display: flex;
        align-items: center;
        gap: 90px;
        // margin: 10px 0 10px 10px;
      }

      .date-label {
        font-size: 15px;
        color: #726868;
        display: flex;
        gap: 10px;
      }
    }

    .card-actions {
      display: flex;
      justify-content: space-evenly;
      padding-top: 20px;

      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;

        .accept-btn,
        .confirm-btn,
        .counter-btn,
        .decline-btn {
          width: 100%;
          height: 100%;
          margin-bottom: 10px;
        }
      }

      .accept-btn,
      .confirm-btn,
      .counter-btn,
      .decline-btn {
        padding: 10px 20px;
        height: 100%;
        font-size: 14px;
        font-weight: 700;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.2s ease;

        &:focus {
          outline: none;
        }

        &:hover {
          opacity: 0.8;
        }

        @media (max-width: 767px) {
          padding: 15px 20px;
        }

        @media (min-width: 767px) {
          min-width: fit-content;
          margin-right: 10px;
        }
      }

      .accept-btn {
        background-color: ${themeGet('primary.0', '#155a93')};
        color: #fff;
      }

      .counter-btn {
        background-color: #deff51;
        color: ${themeGet('primary.0', '#155a93')};
      }

      .decline-btn {
        background-color: #d23857;
        color: #fff;
      }

      .confirm-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: ${themeGet('primary.0', '#155a93')};
        color: #fff;
      }
    }
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  width: 100%;

  @media (min-width: 768px) and (max-width: 1023px) {
    justify-content: center;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`;
