import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Confirmation.module.css';
import Wrapper, { BannerWrapper, OnboardingWrapper } from '../Auth/Auth.style';
import PropertyService from 'service/PropertyService';
import React, { useCallback, useEffect, useState } from 'react';
import Heading from 'components/UI/Heading/Heading';
import { useDispatch, useSelector } from 'react-redux';
import { CiEdit } from 'react-icons/ci';
import {
  overwritePropertyDetails,
  addStoredImages,
  overwritePricing,
  addBookingSettings,
  addThumbnailUrl,
  updateStore,
  setImages,
} from 'features/placeSlice';
import {
  transformPropertyDataFromServer,
  transformPropertyPricing,
} from './utils';
import CancellationPolicy from './CancellationPolicy';
import LocationService from 'service/LocationService';
import PricingService from 'service/PricingService';
import { isEmpty } from 'lodash';
import Navbar from 'components/UI/Navbar/Navbar';
import { HOME_PAGE, IMAGE_CATEGORY_OPTIONS } from 'settings/constant';

const Confirmation = () => {
  let navigate = useNavigate();

  const {
    place,
    type,
    subType,
    location,
    detail,
    pricingDetails,
    bookingSettings,
    propertyId,
    additionalChargesMap,
    onboardingStage,
    amenitiesObject,
    offeringsMap,
  } = useSelector((select) => select);
  const [onboardingPending, setOnboardingPending] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [propertyPhotos, setPropertyPhotos] = useState({});
  const [, setPropertyBookingDetails] = useState({});
  const [, setPropertyPricingDetails] = useState({});

  const [propertyTypeListMap, setPropertyTypeListMap] = useState(null);
  const [propertyOfferingsMap, setPropertyOfferingsMap] = useState(null);
  const [statesMap, setStatesMap] = useState(null);
  const [cityMap, setCityMap] = useState(null);
  const STATE_ID = 1;

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const hostPropertyId = query.get('id');
  const dispatch = useDispatch();

  const getPropertyTypeListMap = async () => {
    if (!propertyTypeListMap) {
      const responseData = await PropertyService.getPropertyTypeList();
      const { responseInformation, result } = responseData;
      if (responseInformation.responseCode === 0) {
        const dataMap = {};
        result.forEach((item) => {
          dataMap[item.id] = { ...item };
        });
        setPropertyTypeListMap({ ...dataMap });
      }
    }
  };

  const transformBookingSetting = (data) => {
    const {
      approvalRequiredForBooking,
      checkInTime,
      checkOutTime,
      maxDayBooking,
      minDayBooking,
    } = data;
    let { cancellationPolicy, propertyPolicy } = data;
    cancellationPolicy = JSON.parse(cancellationPolicy);
    propertyPolicy = JSON.parse(propertyPolicy);

    const bookingDetails = {
      checkInTime: checkInTime,
      checkOutTime: checkOutTime,
      bookingApproval:
        approvalRequiredForBooking !== null &&
        (approvalRequiredForBooking ? 'onApproval' : 'instant'),
      maxDayBooking: maxDayBooking,
      minDayBooking: minDayBooking,
      cancellationPolicy: {
        Upto4H: {
          fullRefund:
            Number(cancellationPolicy[0]?.refundPercentage) === 100
              ? true
              : false,
          noRefund:
            Number(cancellationPolicy[0]?.refundPercentage) === 0
              ? true
              : false,
          partialRefund: cancellationPolicy[0]?.refundPercentage,
        },
        Upto12H: {
          fullRefund:
            Number(cancellationPolicy[1]?.refundPercentage) === 100
              ? true
              : false,
          noRefund:
            Number(cancellationPolicy[1]?.refundPercentage) === 0
              ? true
              : false,
          partialRefund: cancellationPolicy[1]?.refundPercentage,
        },
        Upto24H: {
          fullRefund:
            Number(cancellationPolicy[2]?.refundPercentage) === 100
              ? true
              : false,
          noRefund:
            Number(cancellationPolicy[2]?.refundPercentage) === 0
              ? true
              : false,
          partialRefund: cancellationPolicy[2]?.refundPercentage,
        },
        Upto72H: {
          fullRefund:
            Number(cancellationPolicy[3]?.refundPercentage) === 100
              ? true
              : false,
          noRefund:
            Number(cancellationPolicy[3]?.refundPercentage) === 0
              ? true
              : false,
          partialRefund: cancellationPolicy[3]?.refundPercentage,
        },
        Upto7D: {
          fullRefund:
            Number(cancellationPolicy[4]?.refundPercentage) === 100
              ? true
              : false,
          noRefund:
            Number(cancellationPolicy[4]?.refundPercentage) === 0
              ? true
              : false,
          partialRefund: cancellationPolicy[4]?.refundPercentage,
        },
        MoreThan7D: {
          fullRefund:
            Number(cancellationPolicy[5]?.refundPercentage) === 100
              ? true
              : false,
          noRefund:
            Number(cancellationPolicy[5]?.refundPercentage) === 0
              ? true
              : false,
          partialRefund: cancellationPolicy[5]?.refundPercentage,
        },
      },
      restrictions: propertyPolicy ? [...propertyPolicy] : null,
    };
    return bookingDetails;
  };

  const getCityMap = async (stateId) => {
    if (!cityMap && stateId) {
      const responseData = await LocationService.getCityListForState(stateId);
      const { responseInformation, result } = responseData;
      if (responseInformation.responseCode === 0) {
        const dataMap = {};
        result.forEach((item) => {
          dataMap[item.id] = { ...item };
        });
        setCityMap(dataMap);
        return dataMap;
      }
    }
  };

  const getStatesMap = async () => {
    if (!statesMap) {
      const responseData = await LocationService.getSupportedStateList();
      const { responseInformation, result } = responseData;
      if (responseInformation.responseCode === 0) {
        const dataMap = {};
        result.forEach((item) => {
          dataMap[item.id] = { ...item };
        });
        setStatesMap(dataMap);
      }
    }
  };

  const getPropertyOfferingsMap = async () => {
    if (!propertyOfferingsMap) {
      const responseData = await PropertyService.getPropertyDetailsFieldList();

      const { responseInformation, result } = responseData;
      if (responseInformation.responseCode === 0) {
        const offerMap = {};
        result.forEach((element) => {
          offerMap[element.id] = element.name;
        });
        setPropertyOfferingsMap({ ...offerMap });
      }
    }
  };

  const getPropertyDetailsById = useCallback(async () => {
    if (propertyTypeListMap && statesMap && propertyOfferingsMap) {
      const responseData = await PropertyService.getPropertyDetailsById(
        hostPropertyId || propertyId,
      );
      const { responseInformation, result } = responseData;
      if (responseInformation.responseCode === 0) {
        const savedPropertyDetails = result;
        await getStatesMap();
        const cityMap = await getCityMap(1);
        const typeMap = await PropertyService.getPropertyOfferingsList();

        const dataToSet = transformPropertyDataFromServer(
          savedPropertyDetails,
          propertyTypeListMap,
          propertyOfferingsMap,
          cityMap,
          typeMap.result,
        );
        setPropertyDetails(dataToSet);
        dispatch(overwritePropertyDetails(dataToSet));
      }
      //if (responseData) setPropertyDetails(responseData.result);
      //Update in store and read here as well
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostPropertyId, propertyTypeListMap, statesMap, propertyOfferingsMap]);

  const getPropertyPhotos = useCallback(() => {
    PropertyService.getPhotos(hostPropertyId || propertyId)
      .then((responseData) => {
        const { responseInformation } = responseData;
        if (responseInformation.responseCode === 0) {
          setPropertyPhotos(responseData.result);
          dispatch(setImages(responseData.result));
          dispatch(addStoredImages(responseData.result?.imageUrlList));
          if (responseData.result?.thumbnail) {
            dispatch(addThumbnailUrl(responseData.result?.thumbnail));
          }
        }
        //Update in store and read here as well
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostPropertyId, onboardingStage]);

  const getBookingSettingsForProperty = useCallback(() => {
    PropertyService.getBookingSettingsByPropertyId(hostPropertyId || propertyId)
      .then((responseData) => {
        const { responseInformation, result } = responseData;
        if (responseInformation.responseCode === 0) {
          const dataSet = transformBookingSetting(result);
          setPropertyBookingDetails(dataSet);
          dispatch(addBookingSettings(dataSet));
        }
        //Update in store and read here as well
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostPropertyId, onboardingStage]);

  const getPricingDetailsForProperty = useCallback(() => {
    PricingService.getPropertyPricingDetails(hostPropertyId || propertyId)
      .then((responseData) => {
        const { responseInformation, result } = responseData;
        if (responseInformation.responseCode === 0) {
          const dataSet = transformPropertyPricing(result);
          setPropertyPricingDetails(dataSet);
          dispatch(overwritePricing(dataSet));
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hostPropertyId, onboardingStage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getAllData = async () => {
      if (hostPropertyId || propertyId) {
        await getPropertyTypeListMap();
        await getStatesMap();
        await getPropertyOfferingsMap();
        await getPropertyDetailsById();
        getPropertyPhotos();
        getBookingSettingsForProperty();
        getPricingDetailsForProperty();
      }
    };

    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getBookingSettingsForProperty,
    getPricingDetailsForProperty,
    getPropertyDetailsById,
    getPropertyPhotos,
    hostPropertyId,
  ]);

  const onConfirmClick = async () => {
    try {
      const res = await PropertyService.completePropertyOnboarding({
        propertyId: propertyId || localStorage.getItem('id'),
      });
      if (!res.responseInformation.responseCode) {
        setOnboardingPending(false);
        dispatch(updateStore(true));
      }
    } catch (error) {
      console.log('confirm error', error);
      return;
    }
  };

  const offeringsMapToShow = !isEmpty(propertyDetails?.offeringsMap)
    ? propertyDetails?.offeringsMap
    : offeringsMap;
  const amenitiesObjectToShow = !isEmpty(propertyDetails?.amenitiesObject)
    ? propertyDetails?.amenitiesObject
    : amenitiesObject;

  return (
    <Wrapper>
      <OnboardingWrapper>
        <Navbar />
        {onboardingPending ? (
          <div className={styles.container}>
            <h1 style={{ paddingBottom: '40px' }}>Confirm Details</h1>
            <Heading as="h3" content="Place :" />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                fontSize: '20px',
                cursor: 'pointer',
              }}
              onClick={() =>
                navigate(`/host/description?id=${hostPropertyId || propertyId}`)
              }
            >
              <CiEdit />
            </div>

            <div className={styles.option}>
              <div className={styles.iconText}>{place.name}</div>
            </div>

            <Heading as="h3" content="Type of Place :" />
            <div className={styles.option}>
              <div className={styles.iconText}>
                {type?.name} {subType?.name && <> - {subType?.name}</>}
              </div>
            </div>

            <Heading as="h3" content="Address :" />
            <div className={styles.option}>
              <div className={styles.addressItem}>
                <h4>Country/region : </h4>
                <span className={styles.addressText}>{location.country}</span>
              </div>
              {location.addressLine1 && (
                <div className={styles.addressItem}>
                  <h4>Flat, house, etc. (if applicable) :</h4>
                  <span className={styles.addressText}>
                    {location.addressLine1}
                  </span>
                </div>
              )}
              <div className={styles.addressItem}>
                <h4>Street address :</h4>
                <span className={styles.addressText}>
                  {location.addressLine2}
                </span>
              </div>
              <div className={styles.addressItem}>
                <h4>PIN code :</h4>
                <span className={styles.addressText}>{location.pincode}</span>
              </div>
              {location.landmark && (
                <div className={styles.addressItem}>
                  <h4>Nearby landmark (if applicable) :</h4>
                  <span className={styles.addressText}>
                    {location.landmark}
                  </span>
                </div>
              )}
              {location.district && (
                <div className={styles.addressItem}>
                  <h4>District/locality :</h4>
                  <span className={styles.addressText}>
                    {location.district}
                  </span>
                </div>
              )}
              <div className={styles.addressItem}>
                <h4>City / town :</h4>
                <span className={styles.addressText}>
                  {cityMap && cityMap[location?.cityId]?.name}
                </span>
              </div>
              <div className={styles.addressItem}>
                <h4>State/union territory :</h4>
                <span className={styles.addressText}>
                  {statesMap && statesMap[STATE_ID]?.name}
                </span>
              </div>
            </div>

            <Heading as="h3" content="Property offerings :" />
            <div className={styles?.option}>
              {offeringsMapToShow &&
                Object.keys(offeringsMapToShow).map((offeringKey) => {
                  const offeringValue = offeringsMapToShow[offeringKey];
                  return (
                    <div className={styles.flex}>
                      <h4>{offeringValue.detailsName}: </h4>
                      <span className={styles.addressText}>
                        {offeringValue.detailsValue}
                      </span>
                    </div>
                  );
                })}
            </div>

            <Heading as="h3" content="Details :" />
            <div className={styles.option}>
              <div className={styles.detail}>
                <h4>Title : </h4>
                <span className={styles.addressText}>{detail.title}</span>
              </div>
              <div className={styles.detail}>
                <h4>Descriptions : </h4>
                <span className={styles.addressText}>{detail.description}</span>
              </div>
              <div className={styles.detail}>
                <h4>Link to your property on other platforms:</h4>
                <span className={styles.addressText}>{detail.bnbUrl}</span>
              </div>
            </div>

            <Heading as="h3" content="Amenities :" />
            <div className={styles.option}>
              <div className={styles.grid}>
                {amenitiesObjectToShow?.length > 0 &&
                  amenitiesObjectToShow?.map((item, index) => (
                    <div key={index} className={styles.card}>
                      <p style={{ marginBottom: '0px' }}>{item.name}</p>
                    </div>
                  ))}
              </div>
            </div>
            <Heading as="h3" content="Property Photos :" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                fontSize: '20px',
                cursor: 'pointer',
              }}
              onClick={() =>
                navigate(
                  `/host/photo-upload?id=${hostPropertyId || propertyId}`,
                )
              }
            >
              <CiEdit />
            </div>
            <div className={styles.option}>
              <div>
                {/* {!isEmpty(propertyPhotos?.thumbnail) && (
                  <div key="thumbnail" className={styles.card}>
                    <img src={Object.values(propertyPhotos.thumbnail)[0]} alt="property pics" />
                  </div>
                )} */}
                {!isEmpty(propertyPhotos?.imageUrlList) ? (
                  <div>
                    {Object.keys(propertyPhotos?.imageUrlList).map(
                      (imageURLCategory) => {
                        return (
                          <div>
                            <h4>{IMAGE_CATEGORY_OPTIONS[imageURLCategory]}</h4>
                            <div
                              style={{ display: 'flex', margin: '14px 0px' }}
                            >
                              {propertyPhotos?.imageUrlList[imageURLCategory]
                                ?.length > 0 &&
                                propertyPhotos.imageUrlList[
                                  imageURLCategory
                                ]?.map((item, index) => (
                                  <div key={index} className={styles.card}>
                                    {Object.values(
                                      propertyPhotos?.thumbnail,
                                    )[0] === item && (
                                      <div className={styles.thumbnailOverlay}>
                                        Primary
                                      </div>
                                    )}
                                    <img src={item} alt="property pics" />
                                  </div>
                                ))}
                            </div>{' '}
                          </div>
                        );
                      },
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <Heading as="h3" content="Pricing Details :" />

            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                fontSize: '20px',
                cursor: 'pointer',
              }}
              onClick={() =>
                navigate(
                  `/host/property-listing?id=${hostPropertyId || propertyId}`,
                )
              }
            >
              <CiEdit />
            </div>
            <div className={styles.option}>
              <div className={styles.marginLeft10}>
                <div className={styles.flex}>
                  <h4>Maximum Number of Guests:</h4>
                  <span className={styles.addressText}>
                    {pricingDetails.maxGuestAllowed.numberOfGuests}
                  </span>
                </div>
                <div className={styles.flex}>
                  <h4>Default Price: </h4>
                  <span className={styles.addressText}>
                    ₹{pricingDetails.defaultPrice}
                  </span>
                </div>
              </div>
              {pricingDetails?.additionalCharges?.map((additionalCharge) => {
                const {
                  additionalChargeId,
                  chargeType,
                  pricePerNight,
                  additionalChargeName,
                } = additionalCharge;
                return (
                  <div key={additionalChargeId}>
                    <h3 style={{ marginBottom: 10 }}>
                      {additionalChargesMap[additionalChargeId] ||
                        additionalChargeName}
                    </h3>
                    <div className={styles.marginLeft10}>
                      <div className={styles.flex}>
                        <span className={styles.addressText}>{chargeType}</span>
                        <span className={styles.addressText}>
                          ₹{pricePerNight}
                        </span>
                      </div>{' '}
                    </div>
                  </div>
                );
              })}

              <h3>Discounts</h3>
              <div className={styles.marginLeft10}>
                <div className={styles.flex}>
                  <h4>Less Than 24 Hours : </h4>
                  <span className={styles.addressText}>
                    {pricingDetails.discounts.lessThan24Hours}%
                  </span>
                </div>
                <div className={styles.flex}>
                  <h4>Between 24 Hours to 72 Hours : </h4>
                  <span className={styles.addressText}>
                    {pricingDetails.discounts.between24HoursTo72Hours}%
                  </span>
                </div>
                <div className={styles.flex}>
                  <h4 style={{ width: '55%' }}>
                    Equal to or More Than 72 Hours :{' '}
                  </h4>
                  <span className={styles.addressText}>
                    {pricingDetails.discounts.equalToOrMoreThan72Hours}%
                  </span>
                </div>
                <div className={styles.flex}>
                  <h4>Equal to or More Than 7 Days : </h4>
                  <span className={styles.addressText}>
                    {pricingDetails.discounts.equalToOrMoreThan7Days}%
                  </span>
                </div>
              </div>
              <h3>
                How much below the base price per night are you willing to
                accept offers from interested customers?
              </h3>
              <div
                className={styles.marginLeft10}
                style={{ borderBottom: '0px' }}
              >
                <h4>Less Than 24 Hours : </h4>
                <div className={`${styles.flex} ${styles.marginTop10}`}>
                  <span className={styles.addressText}>
                    Start : {pricingDetails.basePrice.lessThan24Hours.start}%
                  </span>
                  <span className={styles.addressText}>
                    End : {pricingDetails.basePrice.lessThan24Hours.end}%
                  </span>
                </div>
                <h4>Between 24 Hours to 72 Hours : </h4>
                <div className={`${styles.flex} ${styles.marginTop10}`}>
                  <span className={styles.addressText}>
                    Start :{' '}
                    {pricingDetails.basePrice.between24HoursTo72Hours.start}%
                  </span>
                  <span className={styles.addressText}>
                    End : {pricingDetails.basePrice.between24HoursTo72Hours.end}
                    %
                  </span>
                </div>
                <h4 style={{ width: '100%' }}>
                  Equal to or More Than 72 Hours :{' '}
                </h4>
                <div className={`${styles.flex} ${styles.marginTop10}`}>
                  <span className={styles.addressText}>
                    Start :{' '}
                    {pricingDetails.basePrice.equalToOrMoreThan72Hours.start}%
                  </span>
                  <span className={styles.addressText}>
                    End :{' '}
                    {pricingDetails.basePrice.equalToOrMoreThan72Hours.end}%
                  </span>
                </div>
                <h4>Equal to or More Than 7 Days : </h4>
                <div className={`${styles.flex} ${styles.marginTop10}`}>
                  <span className={styles.addressText}>
                    Start :{' '}
                    {pricingDetails.basePrice.equalToOrMoreThan7Days.start}%
                  </span>
                  <span className={styles.addressText}>
                    End : {pricingDetails.basePrice.equalToOrMoreThan7Days.end}%
                  </span>
                </div>
              </div>
            </div>

            <Heading as="h3" content="Booking Settings :" />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                fontSize: '20px',
                cursor: 'pointer',
              }}
              onClick={() =>
                navigate(
                  `/host/booking-settings?id=${hostPropertyId || propertyId}`,
                )
              }
            >
              <CiEdit />
            </div>
            <div className={styles.option}>
              <div className={styles.marginLeft10}>
                <div className={styles.flex}>
                  <h4>Check-In Time : </h4>
                  <span className={styles.addressText}>
                    {bookingSettings.checkInTime}
                  </span>
                </div>
                <div className={styles.flex}>
                  <h4>Check-Out Time : </h4>
                  <span className={styles.addressText}>
                    {bookingSettings.checkOutTime}
                  </span>
                </div>
                <div className={styles.flex}>
                  <h4>Booking Approval : </h4>
                  <span className={styles.addressText}>
                    {bookingSettings?.bookingApproval === 'onApproval'
                      ? 'On Approval'
                      : 'Instant'}
                  </span>
                </div>
                <div className={styles.flex}>
                  <h4>Min day booking : </h4>
                  <span className={styles.addressText}>
                    {bookingSettings.minDayBooking}
                  </span>
                </div>
                <div className={styles.flex}>
                  <h4>Max day booking : </h4>
                  <span className={styles.addressText}>
                    {bookingSettings.maxDayBooking}
                  </span>
                </div>
              </div>
              <h3>Cancellation Policy</h3>
              <CancellationPolicy policy={bookingSettings.cancellationPolicy} />
              {bookingSettings.restrictions?.length > 0 && (
                <>
                  <h3>Restrictions</h3>
                  <div className={styles.grid}>
                    {bookingSettings.restrictions?.map((item, index) => (
                      <div key={index} className={styles.card}>
                        <p style={{ marginBottom: '0px' }}>{item}</p>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '8px',
                marginTop: '40px',
              }}
            >
              <input
                type="checkbox"
                id="confirmCheckbox"
                style={{ width: 'auto' }}
                checked={isCheckboxChecked}
                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
              />
              <label
                htmlFor="confirmCheckbox"
                style={{ fontWeight: '600', width: 'fit-content' }}
              >
                By clicking here, I confirm that the above details are correct.
                I also agree to BYS{' '}
                <a target="_blank" href="/term-of-use" rel="noreferrer">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a target="_blank" rel="noreferrer" href="/privacy-policy">
                  Privacy policy
                </a>
                .
              </label>
            </div>
            <div className={styles.buttons}>
              {/* <button className={styles.backBtn} onClick={backHandler}>
                Back
              </button> */}
              <button
                className={styles.nextBtn}
                style={{ opacity: isCheckboxChecked ? '1' : '0.6' }}
                onClick={onConfirmClick}
                disabled={!isCheckboxChecked}
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.completeMessage}>
            <img
              className={styles.completeIcon}
              src="/images/complete_onboarding.png"
              alt="Logo"
            />
            <h1>Woohoo, you are on your way to become a BYS Skipper</h1>
            <p>
              We have received your details and will run a quick verification
              process. You will receive a confirmation from our side once its
              verified.
            </p>
            <button
              style={{
                padding: '15px',
                width: ' 30%',
                color: ' white',
                backgroundColor: '#4dcad2',
                cursor: ' pointer',
                borderRadius: '8px',
                border: '1px solid black',
                marginTop: '60px',
                fontWeight: 'bold',
              }}
              onClick={() => navigate(HOME_PAGE)}
            >
              Go to Dashboard
            </button>
          </div>
        )}
      </OnboardingWrapper>
      <BannerWrapper>
        <img src="/images/banner/3_updated.png" alt="Auth page banner" />
      </BannerWrapper>
    </Wrapper>
  );
};

export default Confirmation;
