import axios from 'axios';
import { API_BASE_URI } from 'library/constants/api';

const API_BASE_URL = `${API_BASE_URI}/property`;

const BookingService = {
  // Function to post data to the API
  savePropertyBookingSettings: async (data) => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
    try {
      const response = await axios
        .post(`${API_BASE_URL}/savePropertyBookingSettings`, data, { headers })
        .catch((error) => {
          if (error?.response?.status === 403) {
            localStorage.clear();
            sessionStorage.clear();
            window.open('/sign-in', '_self');
          }
        });
      return response.data;
    } catch (error) {
      // Handle error
      console.error('Error posting data:', error);
      throw error;
    }
  },

  // Add more functions for other API calls as needed
};

export default BookingService;
