// General Page Section
export const HOME_PAGE = '/';
export const AGENTS_PAGE = '/agents';

// Listing Single Page Section
export const LISTING_POSTS_PAGE = '/listing';
export const SINGLE_POST_PAGE = '/post';

// Agent Profile Page Section
export const AGENT_PROFILE_PAGE = '/profile';
export const AGENT_PROFILE_FAVORITE = 'favorite-post';
export const AGENT_PROFILE_CONTACT = 'contact';
export const AGENT_ACCOUNT_SETTINGS_PAGE = '/account-settings';
export const AGENT_PROFILE_EDIT_PAGE = 'edit';
export const AGENT_IMAGE_EDIT_PAGE = 'change-image';
export const AGENT_PASSWORD_CHANGE_PAGE = 'change-password';
export const AGENT_PROFILE_DELETE = '/delete';

// Other Pages
export const PRICING_PLAN_PAGE = '/pricing-plan';
export const PRIVACY_PAGE = '/privacy';
export const ADD_HOTEL_PAGE = '/add-hotel';

// Login / Registration Page
export const LOGIN_PAGE = '/sign-in';
export const REGISTRATION_PAGE = '/sign-up';
export const CHANGE_PASSWORD_PAGE = '/change-password';
export const FORGET_PASSWORD_PAGE = '/forget-password';

export const IMAGE_CATEGORY_OPTIONS = {
  livingRoom: 'Living Room',
  drawingRoom:'Drawing Room',
  corridor:'Corridor',
  commonArea: 'Common Area',
  sharedArea: 'Shared Area',
  bedroom: 'Bedroom',
  bathroom: 'Bathroom',
  sharedBathroom: 'Shared Bathroom',
  kidsPlayArea: 'Kids Play area',
  kitchen: 'Kitchen',
  sharedKitchen: 'Shared Kitchen',
  balcony: 'Balcony',
  terrace: 'Terrace',
  verandahPatio: 'Verandah/Patio',
  privatePool: 'Private Pool',
  commonPool: 'Common Pool',
  poolDeck: 'Pool Deck',
  exteriorPropertyView: 'Exterior Property View',
  privateGarden: 'Private Garden',
  commonGarden: 'Common Garden',
  dedicatedWorkspace: 'Dedicated Workspace',
  parking: 'Parking',
  garage: 'Garage',
  gym: 'Gym',
  library: 'Library',
  jacuzzi: 'Jacuzzi',
  bathTub: 'Bath tub',
  propertySurrounding: 'Property Surrounding',
  viewFromProperty: 'View from Property',
  additionalPhotos: 'Additional photos',
  other: 'Other',
};
