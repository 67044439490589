import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './PropertyOfferings.module.css';
import { addOfferings, addOfferingsMap } from 'features/placeSlice';
import Wrapper, {
  BannerWrapper,
  OnboardingWrapper,
  OnboardingContainer,
} from '../Auth/Auth.style';
import {
  ItemWrapper,
  RoomGuestWrapper,
} from 'containers/Home/Search/Search.style';
import InputIncDec from 'components/UI/InputIncDec/InputIncDec';
import { useDispatch, useSelector } from 'react-redux';
import PropertyService from 'service/PropertyService';
import Navbar from 'components/UI/Navbar/Navbar';

const PropertyOfferings = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [itemsCount, setItemsCount] = useState({});
  const offerings = useSelector((select) => select.offerings);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const hostPropertyId = query.get('id');
  const isEdit =
    hostPropertyId !== 'null' &&
    hostPropertyId !== null &&
    hostPropertyId !== undefined
      ? true
      : false;

  useEffect(() => {
    PropertyService.getPropertyDetailsFieldList()
      .then((responseData) => {
        setData(responseData.result);

        const offeringsMap = {};

        if (!offerings) {
          const count = {};
          responseData.result.forEach((element) => {
            count[element.id] = 0;

            offeringsMap[element.id] = element.name;
          });
          setItemsCount(count);
        }

        responseData.result.forEach((element) => {
          offeringsMap[element.id] = element.name;
        });
        dispatch(addOfferingsMap(offeringsMap));
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItemsCount(offerings);
  }, [offerings]);

  const nextHandler = () => {
    dispatch(addOfferings(itemsCount));
    if (isEdit) {
      navigate(`/host/title-and-description?id=${hostPropertyId}`);
      return;
    }

    navigate('/host/title-and-description');
  };

  const backHandler = () => {
    dispatch(addOfferings(itemsCount));
    if (isEdit) {
      navigate(`/host/location?id=${hostPropertyId}`);
      return;
    }
    navigate('/host/location');
  };

  const handleIncrement = (id) => {
    let value = itemsCount[id] + 1;
    setItemsCount({ ...itemsCount, [id]: value });
  };

  const handleDecrement = (id) => {
    if (itemsCount[id] > 0) {
      let value = itemsCount[id] - 1;
      setItemsCount({ ...itemsCount, [id]: value });
    }
  };

  return (
    <Wrapper>
      <OnboardingWrapper>
        <Navbar />
        <OnboardingContainer>
          <h1>Let’s get started with the basics!</h1>
          <p>You can add more details later such as pictures and amenities</p>
          <form style={{ marginTop: '40px' }}>
            <RoomGuestWrapper>
              {data.map((item) => {
                const { id, name } = item;
                return (
                  <ItemWrapper key={id}>
                    <strong>{name}</strong>
                    <InputIncDec
                      id={id}
                      increment={() => handleIncrement(id)}
                      decrement={() => handleDecrement(id)}
                      value={itemsCount[id] || '0'}
                    />
                  </ItemWrapper>
                );
              })}
            </RoomGuestWrapper>
          </form>
          <div className={styles.flex}>
            <button
              style={{
                padding: '15px',
                width: '40%',
                border: '1px solid #4dcad2',
                color: '#4dcad2',
                transition: 'opacity 0.9s',
                cursor: 'pointer',
                borderRadius: '8px',
                marginTop: '60px',
                fontWeight: 'bold',
              }}
              onClick={backHandler}
            >
              Back
            </button>
            <button
              style={{
                padding: '15px',
                width: '40%',
                color: 'white',
                backgroundColor: '#4dcad2',
                transition: 'opacity 0.9s',
                cursor: 'pointer',
                borderRadius: '8px',
                border: '1px solid black',
                marginTop: '60px',
                fontWeight: 'bold',
              }}
              onClick={nextHandler}
            >
              Next
            </button>
          </div>
        </OnboardingContainer>
      </OnboardingWrapper>

      <BannerWrapper>
        <img src="/images/banner/4_updated.png" alt="Auth page banner" />
      </BannerWrapper>
    </Wrapper>
  );
};

export default PropertyOfferings;
