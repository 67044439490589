import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './TitleAndDescription.module.css';

import Wrapper, {
  BannerWrapper,
  OnboardingWrapper,
  OnboardingContainer,
} from '../Auth/Auth.style';
import { useDispatch, useSelector } from 'react-redux';
import { addDetails } from 'features/placeSlice';
import Navbar from 'components/UI/Navbar/Navbar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from "dompurify";

const MAX_TITLE_LENGTH = 160;
const MAX_DESC_LENGTH = 2500;

const TitleAndDescription = () => {
  // const modules = {
  //   toolbar: [
  //     [{ header: [1, 2, 3, 4, 5, 6, false] }],
  //     ["bold", "italic", "underline", "strike", "blockquote"],
  //     [{ size: [] }],
  //     [{ font: [] }],
  //     [{ align: ["right", "center", "justify"] }],
  //     // [{ list: "ordered" }, { list: "bullet" }],
  //     // ["link", "image"],
  //     [{ color: ["red", "#785412"] }],
  //     [{ background: ["red", "#785412"] }]
  //   ]
  // };

  const [text, setText] = useState('');
  const [textLength, setTextLength] = useState(1);
  const handleChange = (content, delta, source, editor) => {
    const sanitizedValue = DOMPurify.sanitize(content);
    setText(sanitizedValue);
    const length = editor.getLength();
    setTextLength(length);
    setDetails({ ...details, description: sanitizedValue });
  };

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [error, setError] = useState({});
  const [details, setDetails] = useState({
    title: '',
    description: '',
    bnbUrl: '',
  });
  const detail = useSelector((select) => select.detail);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const hostPropertyId = query.get('id');
  const isEdit =
    hostPropertyId !== 'null' &&
    hostPropertyId !== null &&
    hostPropertyId !== undefined
      ? true
      : false;

  useEffect(() => {
    setDetails(detail);
    setText(detail.description);
    if (detail.description.length > 0) {
      setTextLength(detail.description.length);
    }
  }, [detail]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = DOMPurify.sanitize(value);
    if (name && value) {
      setError((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
    setDetails({ ...details, [name]: sanitizedValue });
  };

  const validateEntries = () => {
    let newErrors = {};

    if (!details.title.trim()) {
      newErrors.title = 'Please enter title';
    }
    if (!details.description.trim()) {
      newErrors.description = 'Please enter description';
    }
    if (details.title.length > MAX_TITLE_LENGTH) {
      newErrors.title = `Max ${MAX_TITLE_LENGTH} characters allowed in the Title`;
    }
    if (details.description.length > MAX_DESC_LENGTH) {
      newErrors.title = `Max ${MAX_DESC_LENGTH} characters allowed in the Description`;
    }

    if (Object.keys(newErrors).length > 0) {
      setError(newErrors);
    }

    if (!details.title || !details.description) return true;
    return false;
  };

  const nextHandler = () => {
    if (validateEntries()) {
      return;
    }
    dispatch(addDetails(details));
    if (isEdit) {
      navigate(`/host/amenities?id=${hostPropertyId}`);
      return;
    }
    navigate('/host/amenities');
  };

  const backHandler = () => {
    dispatch(addDetails(details));
    if (isEdit) {
      navigate(`/host/property-offerings?id=${hostPropertyId}`);
      return;
    }
    navigate(`/host/property-offerings`);
  };

  return (
    <Wrapper>
      <OnboardingWrapper>
        <Navbar />

        <OnboardingContainer>
          <h1>Now, let's describe your property for users</h1>
          <p>Short titles work better. You can always change it later.</p>
          <form style={{ marginTop: 40 }}>
            <label>
              Title
              <input
                type="text"
                name="title"
                maxlength="50"
                title="Maximum 50 characters allowed"
                value={details.title}
                onChange={handleInputChange}
              />
              <small>{details.title.length}/50 characters</small>
              {error.title && (
                <div
                  style={{
                    color: 'red',
                    marginTop: '2px',
                    marginBottom: '2px',
                  }}
                >
                  {error.title}
                </div>
              )}
            </label>
            <label>
              Description
              {/* <textarea
                name="description"
                maxlength={MAX_DESC_LENGTH}
                title={`Maximum ${MAX_DESC_LENGTH} characters allowed`}
                onChange={handleInputChange}
                rows={6}
                value={details.description || ''}
              ></textarea> */}
              <ReactQuill
                value={text}
                onChange={(content, delta, source, editor) =>
                  handleChange(content, delta, source, editor)
                }
                name="description"
              />
              <small>
                {textLength - 1}/{MAX_DESC_LENGTH} characters
              </small>
              {error.description && (
                <div
                  style={{
                    color: 'red',
                    marginTop: '2px',
                    marginBottom: '2px',
                  }}
                >
                  {error.description}
                </div>
              )}
            </label>
            <label>
              Link to your property on other platforms (e.g. AirBnB)
              <input
                type="text"
                name="bnbUrl"
                maxlength="50"
                placeholder="Property URL if it is listed on AirBnB"
                value={details.bnbUrl}
                onChange={handleInputChange}
              />
              {error.bnbUrl && (
                <div
                  style={{
                    color: 'red',
                    marginTop: '2px',
                    marginBottom: '2px',
                  }}
                >
                  {error.bnbUrl}
                </div>
              )}
            </label>
          </form>
          <div className={styles.flex}>
            <button
              style={{
                padding: '15px',
                width: '40%',
                border: '1px solid #4dcad2',
                color: '#4dcad2',
                transition: 'opacity 0.9s',
                cursor: 'pointer',
                borderRadius: '8px',
                marginTop: '60px',
                fontWeight: 'bold',
              }}
              onClick={backHandler}
            >
              Back
            </button>
            <button
              style={{
                padding: '15px',
                width: '40%',
                color: 'white',
                backgroundColor: '#4dcad2',
                transition: 'opacity 0.9s',
                cursor: 'pointer',
                borderRadius: '8px',
                border: '1px solid black',
                marginTop: '60px',
                fontWeight: 'bold',
              }}
              onClick={nextHandler}
            >
              Next
            </button>
          </div>
        </OnboardingContainer>
      </OnboardingWrapper>

      <BannerWrapper>
        <img src="/images/banner/5_updated.png" alt="Auth page banner" />
      </BannerWrapper>
    </Wrapper>
  );
};

export default TitleAndDescription;
