import styles from './Confirmation.module.css';

const CancellationPolicy = (props) => {
  const { policy } = props;

  return Object.keys(policy).map((key) => (
    <div key={key} className={styles.marginLeft10}>
      <h4>{key.replace(/([A-Z])/g, ' $1')} :</h4>
      <div className={`${styles.flex} ${styles.marginTop10}`}>
        <span className={styles.addressText}>
          Full Refund: {policy[key].fullRefund ? 'Yes' : 'No'}
        </span>
        <span className={styles.addressText}>
          No Refund: {policy[key].noRefund ? 'Yes' : 'No'}
        </span>
        <span className={styles.addressText}>
          Partial Refund: {policy[key].partialRefund}%
        </span>
      </div>
    </div>
  ));
};

export default CancellationPolicy;
