import { useEffect, useState } from 'react';
import { format, addDays } from 'date-fns';
import { makeQueryString } from '../utils/makeQueryString';
import DatePickerInput from './daterange-picker';
import LocationInput from './location-input';
import { MapMarkerIcon } from '../icons/map-marker';
import { CalenderIcon } from '../icons/calender';
import Text from '../UiNew/typography/text';
import Button from '../UiNew/button';
import CounterFilter from 'components/Explore/CounterFilter';
import SelectBox from 'components/UiNew/SelectBox';
import LocationService from 'service/LocationService';
import Footer from 'containers/Layout/Footer/Footer';
import Header from 'containers/Layout/Header/Header';
import LayoutProvider from 'context/LayoutProvider';

const SearchForm = () => {
  // const [locationInput, setLocationInput] = useState({
  //   searchedLocation: '',
  //   searchedPlaceAPIData: [],
  // });
  // const [searchBox, setSearchBox] = useState(null);
  const [cityData, setCityData] = useState([]);
  const [formData, setFormData] = useState({
    city: cityData[0],
    state: 'Goa',
    startDate: new Date(),
    endDate: new Date(),
    adults: 0,
    kids: 0,
    pets: 0,
    infants: 0,
  });

  useEffect(() => {
    LocationService.getCityListForSearch(1)
      .then((responseData) => {
        console.log('result: ', responseData.result);
        setCityData([
          { name: 'Select City', disabled: true },
          ...responseData.result,
        ]);
      })
      .catch((error) => {
        // Handle error
        console.error('Error fetching data:', error);
      });
  }, [formData?.city]);

  // const onLoad = (ref) => setSearchBox(ref);
  // const onPlacesChanged = () => {
  //   const places = searchBox?.getPlaces();
  //   setLocationInput({
  //     searchedLocation: places && places[0] && places[0].formatted_address,
  //     searchedPlaceAPIData: places ? places : [],
  //   });
  //   // const places = searchBox?.getPlaces();
  //   // setFormData((prev) => ({
  //   //   ...prev,
  //   //   location: {
  //   //     searchedLocation: places && places[0] && places[0].formatted_address,
  //   //     searchedPlaceAPIData: places ? places : [],
  //   //   },
  //   // }));
  // };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    let queryString = '';
    const queryObj = {
      location: formData.location?.searchedLocation,
      departureDate: format(formData.startDate, 'yyyy-MM-dd'),
      returnDate: format(formData.endDate, 'yyyy-MM-dd'),
    };
    queryString = makeQueryString(queryObj);
    console.log(queryString);
    // router.push(`${Routes.public.explore}?${queryString}`);
  };

  return (
    <LayoutProvider>
      <Header />
      <div className="relative flex min-h-full items-end justify-center px-4 pt-48 before:absolute before:left-0 before:top-0 before:z-[1] before:block before:h-1/4 before:w-full before:bg-gradient-to-b before:from-black/60 sm:flex-none sm:justify-start sm:px-0 sm:pl-6 sm:pt-[120px] md:pt-0 md:pl-0 3xl:pt-[142px] 4xl:pl-[200px]">
        <img
          src="/images/banner/3.jpg"
          alt="Location Banner"
          className="aspect-[2/1] w-full bg-gray-lighter object-cover hidden md:block"
        />

        <form
          noValidate
          onSubmit={handleFormSubmit}
          className="absolute z-50 w-full max-w-[450px] rounded-lg bg-white p-6 top-0 md:top-8 shadow-2xl sm:m-0 sm:max-w-[380px] sm:p-7 sm:pt-9 md:max-w-[400px] md:ml-16 md:mb-5 md:shadow-none lg:rounded-xl xl:max-w-[460px] xl:p-9 4xl:max-w-[516px] 4xl:p-12"
        >
          <div className="mb-3 sm:mb-0">
            <Text
              tag="h1"
              className="leading-12 mb-2 mt-0 !text-xl !font-black uppercase text-gray-dark sm:!text-[28px] sm:!leading-9 4xl:!text-4xl 4xl:!leading-[52px]"
            >
              Discover the <br className="hidden sm:block" />
              new world
            </Text>
            <Text className="mb-5 hidden leading-6 !text-secondary sm:block 3xl:leading-8 4xl:mb-6 4xl:text-lg">
              Compare prices from 200+ booking sites to help you find the lowest
              price on the right hotel for you.
            </Text>
          </div>

          <div className="flex gap-2">
            <LocationInput
              label="State"
              icon={<MapMarkerIcon className="h-6 w-6 text-gray" />}
              className="mb-3 w-[190px]"
              value={formData.state}
              onChange={(event) =>
                setFormData((prev) => ({
                  ...prev,
                  state: event.target.value,
                }))
              }
              disabled
            />
            <SelectBox
              value={formData.city?.id}
              variant="outline"
              options={cityData}
              optionIcon={false}
              arrowIconClassName="!right-2"
              labelClassName="flex-shrink-0"
              className="flex justify-between items-center gap-3 capitalize xl:flex md:[&>li]:!text-base"
              optionsContainerClassName="w-full right-0 md:[&>li]:!text-base"
              buttonClassName="!px-4 !py-2 flex justify-between !w-[190px] text-lg cursor-pointer !pr-10 h-[60px] mb-3 rounded-xl font-bold"
              onChange={(data) =>
                setFormData((prev) => ({
                  ...prev,
                  city: cityData.find((option) => option.id === data),
                }))
              }
            />
          </div>

          {/* <SearchAutocomplete
          onLoad={onLoad}
          onPlacesChanged={onPlacesChanged}
          loader={
            <LocationInput
              label="Loading . . ."
              icon={<MapMarkerIcon className="h-6 w-6 text-gray" />}
              className="mb-3"
              disabled
            />
          }
        >
          <LocationInput
            label="Location"
            icon={<MapMarkerIcon className="h-6 w-6 text-gray" />}
            className="mb-3"
            value={locationInput.searchedLocation || ''}
            onChange={(event) =>
              setLocationInput({
                ...locationInput,
                searchedLocation: event.target.value,
              })
            }
            // value={formData.location?.searchedLocation || ''}
            // onChange={(event) =>
            //   setFormData((prev) => ({
            //     ...prev,
            //     location: {
            //       ...prev.location,
            //       searchedLocation: event.target.value,
            //     },
            //   }))
            // }
          />
        </SearchAutocomplete> */}
          <div className="flex gap-2 ">
            <DatePickerInput
              label="Check In"
              selected={formData.startDate}
              dateFormat="eee dd / LL / yy"
              icon={<CalenderIcon className="h-6 w-6 text-gray" />}
              onChange={(date) => {
                setFormData((prev) => ({
                  ...prev,
                  startDate: date,
                  endDate: addDays(date, 1),
                }));
              }}
              minDate={new Date()}
              containerClass="mb-3 w-[210px]"
              popperClassName="homepage-datepicker"
            />
            <DatePickerInput
              label="Check Out"
              selected={formData.endDate}
              dateFormat="eee dd / LL / yy"
              icon={<CalenderIcon className="h-6 w-6 text-gray" />}
              onChange={(date) =>
                setFormData((prev) => ({
                  ...prev,
                  endDate: date,
                }))
              }
              minDate={formData.endDate}
              containerClass="mb-3 w-[210px]"
              popperClassName="homepage-datepicker"
            />
          </div>
          <div className="rounded-lg border border-gray-300 py-1 mb-4">
            <CounterFilter
              value={formData.adults}
              setValue={(val) =>
                setFormData((prev) => ({
                  ...prev,
                  adults: val,
                }))
              }
              label={'Adults'}
            />
            <CounterFilter
              value={formData.kids}
              setValue={(val) =>
                setFormData((prev) => ({
                  ...prev,
                  kids: val,
                }))
              }
              label={'Kids'}
            />
            <CounterFilter
              value={formData.pets}
              setValue={(val) =>
                setFormData((prev) => ({
                  ...prev,
                  pets: val,
                }))
              }
              label={'Pets'}
            />
            <CounterFilter
              value={formData.infants}
              setValue={(val) =>
                setFormData((prev) => ({
                  ...prev,
                  infants: val,
                }))
              }
              label={'Infants'}
            />
          </div>
          <Button
            type="submit"
            className="w-full bg-[#4dcad2] !py-[14px] text-sm !font-bold uppercase leading-6 md:!py-[17px] md:text-base lg:!rounded-xl 3xl:!py-[22px]"
            rounded="lg"
            size="xl"
          >
            Search
          </Button>
        </form>
      </div>
      <Footer />
    </LayoutProvider>
  );
};

export default SearchForm;
